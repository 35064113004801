<!-- 合作机构人员新增 -->
<template>
    <div class="addCooperate">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">合作机构人员新增</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单部分 -->
        <el-form ref="form" :model="form" :rules="rules" id="form-1" class="" inline label-width="150px">
            <el-form-item label="工号">
                <el-input v-model="form.jobNumber"  placeholder="请输入工号"></el-input>
            </el-form-item>
            <el-form-item label="客户名称" prop="customerName">
                <el-input v-model="form.customerName" placeholder="请输入客户名称"></el-input>
            </el-form-item>
            <el-form-item label="状态">
               <el-select v-model="form.condition" placeholder="请选择状态" :clearable="true">
                   <el-option v-for="item in form.stateChoose" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="人员姓名" prop="fullName">
                <el-input v-model="form.fullName" placeholder="请输入人员姓名"></el-input>
            </el-form-item>
            <el-form-item label="英文名字">
                <el-input v-model="form.englishName" placeholder="请输入英文名字"></el-input>
            </el-form-item>
            <el-form-item label="登录账号" prop="fullName">
                <el-input v-model="form.account" placeholder="请输入登录账号"></el-input>
            </el-form-item>
            <el-form-item label="客户主责" prop="personLiable">
                <el-input v-model="form.personLiable" placeholder="请输入客户主责"></el-input>
            </el-form-item>
            <el-form-item label="有效截止日期" prop="effectiveDate">
                <el-date-picker v-model="form.effectiveDate" type="date" 
                placeholder="请选择日期" value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="所在部门" prop="department">
                <el-input v-model="form.department" placeholder="请输入所在部门"></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;"  @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                  jobNumber:"",//工号
                  customerName:"",//客户名称 
                  stateChoose: [
                      {label:"在职",value:"1"},
                      {label:"新人",value:"2"},
                      {label:"兼职",value:"3"},
                      {label:"离职",value:"4"},
                  ], //状态选择
                  condition: "1", //状态
                  fullName:"",//人员姓名
                  englishName:"",//英文名字
                  account:"",//登录账号
                  personLiable:"",//客户主责
                  effectiveDate:"",//有效日期
                  department:""//所在部门

                },
                rules:{
                   customerName:[
                       { required: true, message: '请输入客户名称', trigger: 'blur' },
                   ] ,
                   fullName:[
                       { required: true, message: '请输入人员姓名', trigger: 'blur' },
                   ] ,
                   account:[
                       { required: true, message: '请输入登录账号', trigger: 'blur' },
                   ],
                   personLiable:[
                       { required: true, message: '请输入客户主责', trigger: 'blur' },
                   ],
                   effectiveDate:[
                       { required: true, message: '请选择有效日期', trigger: 'change' },
                   ] ,
                   department:[
                       { required: true, message: '请输入所在部门', trigger: 'blur' },
                   ] ,
                },
                bool:false
            }
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) {//提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        if(this.bool){
                            return
                        }   
                        this.bool=true
                       let data = this.form
                       this.$request({url: '/api/cooperation/add', method: 'POST', data: {
                       	number: data.jobNumber,
                       	name: data.customerName,
                       	realname: data.fullName,
                       	englishname: data.englishName,
                       	username: data.account,
                       	status: data.condition,
                       	attr1: data.personLiable,
						end_time: data.effectiveDate,
						department: data.department
                       }}).then(res => {
                          console.log(res)
                       	if(res.code == 1){
                       		this.$message({
                       		  message: '新增列表成功',
                       		  type: 'success'
                       		})
                       		setTimeout(() => {
                       			this.goBack()	
                       		}, 1500)
                       	}else{
                            this.$message({
                              message: res.msg,
                              type: 'error'
                            })
                            setTimeout(() => {
                            	this.goBack()	
                            }, 1500)
                        }
                       }).catch(()=>{
                           this.bool=false
                       })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) {//取消
                this.goBack()
                this.$refs[form].resetFields();
            }
        }
    }
</script>

<style scoped="scoped">
    .addCooperate{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    .el-select {
        width: 300px;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
